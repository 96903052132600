import { MarketingEvent } from '@/types'
import useConfig, { useCachedConfig } from './useConfig'

const useMarketingEvents = () => {
  return useConfig<MarketingEvent[]>('marketing-events')
}

export const useCachedMarketingEvents = () => {
  return useCachedConfig<MarketingEvent[]>('marketing-events')
}

export default useMarketingEvents
