import { TemplateOfTheDayConfig } from '@/types'
import useMarketingEvents from './useMarketingEvents'

const useTemplateOfTheDay = () => {
  const { data: marketingEvents, ...others } = useMarketingEvents()

  const templateOfToday = marketingEvents?.find((event) => event.id === 'template_of_the_day')
  const config: TemplateOfTheDayConfig | null = (templateOfToday?.action?.payload as any) ?? null

  return {
    ...others,
    data: config,
  }
}

export default useTemplateOfTheDay
